import { MainLayout } from '@Components';
import { NotFound404 } from '@Components';

const Custom404Page = () => {
  return (
    <MainLayout>
      <NotFound404 />
    </MainLayout>
  );
};

export default Custom404Page;
